import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import { debounce } from 'lodash';

import AddWhiteIcon from '../../assets/images/icons/add-white.png';
import ErrorIcon from '../../assets/images/icons/error.svg';
import { getCustomer } from '../../lib/customer';
import { Customer } from '../../lib/customer/types';
import { createOrderGroup, updateOrder } from '../../lib/order';
import { Order } from '../../lib/order/types';
import { openSalesCondition } from '../../lib/salesCondition';
import { RootState } from '../../redux';
import { Category } from '../../redux/categories/types';
import CustomerPicker from '../customer-picker';
import LoadingIcon from '../loading-icon';
import Group from './Group';
import ExcelLoad from './actions/ExcelLoad';
import OrderSubmit from './actions/OrderSubmit';
import UndoFlash from './flash/UndoFlash';
import { Status } from './types';
import { combineStatuses } from './utils';

interface Props {
    category: Category;
    disableExcel: boolean;
    enablePriceEdit: boolean;
    errorVisible: boolean;
    hideError: () => void;
    order: Order;
    showError: (_: string) => void;
    hidePrice: boolean;
}

const OrderTable = ({
    category,
    disableExcel,
    enablePriceEdit,
    errorVisible,
    hideError,
    order,
    showError,
    hidePrice,
}: Props) => {
    const oidcUser = useSelector((state: RootState) => state.oidc.user);
    const user = useSelector((state: RootState) => state.user.user);

    const [customer, setCustomer] = useState<Customer | null>(null);
    const [reference, setReference] = useState(order.reference);
    const [groups, setGroups] = useState(order.groups);
    const [dirtyPriceTime, setDirtyPriceTime] = useState(order.dirtyPriceTime);
    const [loadingGroupAdd, setLoadingGroupAdd] = useState(false);
    const [strictMode, setStrictMode] = useState(0);
    const [groupStatus, setGroupStatus] = useState<Record<number, Status>>({});
    const [orderStatus, setOrderStatus] = useState<Status>('EMPTY');
    const [deletedRows, setDeletedRows] = useState<number[]>([]);
    const [returnedRow, setReturnedRow] = useState<number>(0);
    const [salesConditionAccepted, setSalesConditionAccepted] = useState(category.salesCondition === null);

    useEffect(() => {
        if (customer || !oidcUser?.access_token || !order.customer) return;
        getCustomer(oidcUser.access_token, order.customer).then((customer) => setCustomer(customer));
    }, [oidcUser?.access_token, customer, order.customer]);

    useEffect(() => {
        setOrderStatus(combineStatuses(new Set(Object.values(groupStatus))));
    }, [groupStatus]);

    const debouncedOrderUpdate = useMemo(
        () =>
            debounce(
                (customer: string | null, reference: string) =>
                    updateOrder(oidcUser?.access_token ?? '', order.id, { customer, reference }).then(
                        ({ customer, dirtyPriceTime }) => {
                            setCustomer((old: Customer | null) =>
                                customer ? (old ? { ...old, id: customer } : ({ id: customer } as Customer)) : null,
                            );
                            setDirtyPriceTime(dirtyPriceTime);
                        },
                    ),
                300,
            ),
        [oidcUser?.access_token],
    );

    const handleCustomerChange = (customer?: Customer) => {
        setCustomer(customer ?? null);
        debouncedOrderUpdate(customer?.id ?? null, reference);
    };

    const handleReferenceChange = (e: ChangeEvent<HTMLInputElement>) => {
        setReference(e.target.value);
        debouncedOrderUpdate(customer?.id ?? null, e.target.value);
    };

    const handleAddGroup = () => {
        hideError();
        setLoadingGroupAdd(true);
        createOrderGroup(oidcUser?.access_token ?? '', order.id)
            .then((orderGroup) => setGroups((groups) => [...groups, orderGroup]))
            .catch((err) => {
                console.error(err);
                showError(
                    'Uuden merkin/asiakkaan lisääminen epäonnistui. Voit kokeilla päivittää sivun ja ladata ' +
                        'tilauksen tallennetuista tilauksta. Mikäli ongelma jatkuu, olethan yhteydessä ylläpitoon.',
                );
            })
            .finally(() => setLoadingGroupAdd(false));
    };

    const handleGroupStatusChange = (groupId: number) => (status: Status) =>
        setGroupStatus((groupStatus) => ({ ...groupStatus, [groupId]: status }));

    const handleRowDelete = (i: number) => setDeletedRows((deletedRows) => [i, ...deletedRows]);

    const handleRowDeleteUndo = () => {
        const i = deletedRows[0];
        setReturnedRow(i);
        setDeletedRows((deletedRows) => deletedRows.filter((n) => i !== n));
    };

    const handleUndoClose = () => setDeletedRows([]);

    const handleSalesConditionChange = (event: ChangeEvent<HTMLInputElement>) =>
        setSalesConditionAccepted(event.target.checked);

    const handleOpenSalesCondition = useCallback(
        () =>
            oidcUser?.access_token &&
            category.salesCondition &&
            openSalesCondition(oidcUser.access_token, category.salesCondition).catch((err) => {
                console.error(err);
                showError(
                    'Myyntiehtojen avaaminen epäonnistui. Voit kokeilla päivittää sivun ja avata myyntiehdot ' +
                        'uudelleen. Mikäli ongelma jatkuu, olethan yhteydessä ylläpitoon',
                );
            }),
        [oidcUser?.access_token, category.salesCondition],
    );

    return (
        <>
            {/* VIITE */}
            <div>
                <TextField
                    label='Viite'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        disableUnderline: true,
                        inputProps: {
                            maxLength: 40,
                        },
                    }}
                    value={reference}
                    onChange={handleReferenceChange}
                />
            </div>

            {/* CUSTOMER CHANGE */}
            <CustomerPicker
                categoryId={category.id}
                selectedCustomer={customer ?? undefined}
                setSelectedCustomer={handleCustomerChange}
            />

            {/* DEPRECATED FIELDS WARNING */}
            {groups.some((group) => group.rows.some((r) => r.fields.some((f) => f.deprecated))) && (
                <div className='alert alert-error'>
                    <img src={ErrorIcon} alt='Huomio' />
                    HUOM! Tallennetussa tilauksessa on ollut kenttiä jotka ovat nyt poistettuja. Rivit joihin tämä
                    muutos vaikuttaa on merkitty punaisella huutomerkillä. Näet poistetun kentän ja siinä olleen arvon
                    viemällä hiiren huutomerkin päälle.
                </div>
            )}

            {/* SUB ORDERS */}
            <div className='sub-orders'>
                {groups.map((group, i) => (
                    <Fragment key={group.id}>
                        <Group
                            category={category}
                            deleteRow={handleRowDelete}
                            dirtyPriceTime={dirtyPriceTime}
                            enablePriceEdit={enablePriceEdit}
                            group={group}
                            hideError={hideError}
                            order={order}
                            returnedRow={returnedRow}
                            setStatus={handleGroupStatusChange(group.id)}
                            showError={showError}
                            hidePrice={hidePrice}
                            strictMode={strictMode}
                        />
                        {i !== groups.length - 1 && <div className='sub-order__separator'></div>}
                    </Fragment>
                ))}

                {/* ADD ORDER */}
                <button className='btn btn-secondary btn-w-icon' onClick={handleAddGroup} disabled={loadingGroupAdd}>
                    {loadingGroupAdd ? <LoadingIcon /> : <img src={AddWhiteIcon} alt='Lisää uusi merkki/asiakas' />}
                    Lisää uusi merkki/asiakas
                </button>
            </div>

            {category.salesCondition && (
                <div className='order__sales-condition'>
                    <input
                        id='accept-sales-condition'
                        type='checkbox'
                        checked={salesConditionAccepted}
                        onChange={handleSalesConditionChange}
                    />
                    <span>
                        <label htmlFor='accept-sales-condition'>Hyväksyn</label>{' '}
                        <button className='btn btn-link' onClick={handleOpenSalesCondition}>
                            yleiset myynti- ja toimitusehdot
                        </button>
                    </span>
                </div>
            )}
            <div className='order__actions btn-row'>
                <ExcelLoad
                    category={category}
                    customer={customer}
                    hidden={disableExcel || !category.excelLoad || !(customer?.excelAllowed || user?.role === 'ADMIN')}
                />

                <OrderSubmit
                    enableStrictMode={() => setStrictMode(Date.now())}
                    orderId={order.id}
                    salesConditionAccepted={salesConditionAccepted}
                    status={orderStatus}
                />
            </div>

            {/* TODO */}
            <UndoFlash
                handleClose={handleUndoClose}
                handleUndo={handleRowDeleteUndo}
                show={deletedRows.length > 0}
                showHigher={errorVisible}
            />

            {/* make sure there is enough space when the select is opened */}
            <div style={{ marginBottom: 150 }} />
        </>
    );
};

export default OrderTable;
