import { ChangeEventHandler } from 'react';
import Modal from 'react-modal';

import CloseIcon from '../../../assets/images/icons/close.svg';
import LoadingIcon from '../../loading-icon';

interface Props {
    disabled: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
    handleFileInputOnChange: ChangeEventHandler<HTMLInputElement>;
    loading: boolean;
    loadingFile: boolean;
    name?: string;
    open: boolean;
}

const ExcelLoadModal = ({
    disabled,
    handleClose,
    handleSubmit,
    handleFileInputOnChange,
    loading,
    loadingFile,
    name,
    open,
}: Props) => (
    <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
            content: {
                paddingTop: '50px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'column',
            },
        }}
        ariaHideApp={false}
    >
        <button className='modal__close btn btn-icon' onClick={handleClose}>
            <img src={CloseIcon} alt='sulje' />
        </button>
        {loading ? (
            <div className='modal__title-wrap'>
                <div className='modal__title modal__title--center'>
                    <LoadingIcon />
                </div>
            </div>
        ) : (
            <>
                <div className='modal__title-wrap'>
                    <div className='modal__title modal__title--center'>
                        {name ? (
                            `Ladataanko tiedosto '${name}'?`
                        ) : (
                            <span>
                                Valitse ladattava excel-tiedosto
                                <br />
                                <br />
                                {loadingFile ? (
                                    <LoadingIcon />
                                ) : (
                                    <input
                                        type='file'
                                        className='modal__input-file'
                                        accept='.csv,.xlsx'
                                        onChange={handleFileInputOnChange}
                                    />
                                )}
                            </span>
                        )}
                    </div>
                </div>
                <div className='modal__actions btn-row stack-element'>
                    <button className='btn btn-secondary' onClick={handleClose}>
                        Peruuta
                    </button>
                    <button className='btn btn-primary' onClick={handleSubmit} disabled={disabled}>
                        Lataa excel
                    </button>
                </div>
            </>
        )}
    </Modal>
);

export default ExcelLoadModal;
