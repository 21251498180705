import Modal from 'react-modal';
import { Link } from 'react-router-dom';

import CloseIcon from '../../../assets/images/icons/close.svg';

interface Props {
    handleClose: () => void;
    open: boolean;
}

const SubmitSuccessModal = ({ handleClose, open }: Props) => (
    <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
            content: {
                paddingTop: '50px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'column',
            },
        }}
        ariaHideApp={false}
    >
        <button className='modal__close btn btn-icon' onClick={handleClose}>
            <img src={CloseIcon} alt='sulje' />
        </button>
        <div className='modal__title-wrap'>
            <div className='modal__title modal__title--center'>
                Kiitokset tilauksesta, tilaus on lähetetty. Saat vielä kopion tilauksestasi sähköpostiin.
            </div>
        </div>
        <div className='modal__actions btn-row stack-element'>
            <Link className='btn btn-secondary' to='/'>
                Palaa etusivulle
            </Link>
        </div>
    </Modal>
);

export default SubmitSuccessModal;
