import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';

import LoadingIcon from '../../components/loading-icon';
import { RootState } from '../../redux';
import { loadFixOrder } from '../../redux/fix-order/actions';

const FixOrder = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const category = useSelector((state: RootState) => state.fixOrder.category);

    useEffect(() => {
        if (!id) return;
        dispatch(loadFixOrder(id));
    }, []);

    return !category || !category.id ? (
        <div style={{ marginTop: '8rem' }}>
            <LoadingIcon />
        </div>
    ) : (
        <Navigate to={`/kategoria/${category.id}`} />
    );
};

export default FixOrder;
