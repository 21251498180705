import { KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import Select, { OptionProps, StylesConfig, components } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { FieldOption } from '../../../redux/categories/types';
import { api } from '../../../utils/api';
import { CustomOptionType, ISelectEditorParams } from '../types';

const createCustomSelectStyles = (colWidth: number): StylesConfig<CustomOptionType, false> => ({
    control: (provided) => ({
        ...provided,
        height: 40,
        minWidth: colWidth,
        width: '100%',
        borderColor: 'rgba(33, 150, 243, 0.4) !important',
        boxShadow: 'none !important',
    }),
    menu: () => ({
        width: 'max-content',
        minWidth: '100%',
        position: 'relative',
    }),
    menuList: (provided) => ({
        ...provided,
        position: 'unset',
        paddingTop: '.5rem',
        backgroundColor: '#FFF',
    }),
    option: (provided, { isFocused }) => ({
        ...provided,
        cursor: 'pointer',
        [`& .sub-order__select-option-large-img`]: {
            display: isFocused ? 'block' : 'none',
        },
    }),
});

const fieldOptionToCustomOptionType = (option: FieldOption): CustomOptionType => ({
    value: option.id,
    label: option.name,
    image: option?.image?.image,
});

const customOptionTypeToFieldOption = (option: CustomOptionType): FieldOption => {
    return { id: option.value, name: option.label, image: option.image ? { image: option.image } : null };
};

const CustomOption = ({ children, ...rest }: OptionProps<CustomOptionType, false>) => (
    <components.Option {...rest}>
        <div className='sub-order__select-option'>
            {rest.data.image ? (
                <>
                    <div className='sub-order__select-option-large-img'>
                        <img src={api.mediumThumbnail(rest.data.image)} alt='' role='presentation' />
                    </div>
                    <div className='sub-order__select-option-img'>
                        <img src={api.smallThumbnail(rest.data.image)} alt='' role='presentation' />
                    </div>
                </>
            ) : null}
            {children}
        </div>
    </components.Option>
);

const ISelectEditor = ({ value, onValueChange, column, api, values }: ISelectEditorParams) => {
    const [directionKey, setDirectionKey] = useState<'ArrowLeft' | 'ArrowRight'>('ArrowRight');

    // FIXME
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const selectRef = useRef<Select<CustomOptionType>>(null);

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        switch (event.key) {
            case 'ArrowLeft':
            case 'ArrowRight':
                if (value?.id === selectRef.current.state.focusedOption.value) {
                    if (event.key === 'ArrowLeft') api.tabToPreviousCell();
                    else api.tabToNextCell();
                } else if (selectRef.current.state.focusedOption) {
                    onValueChange(customOptionTypeToFieldOption(selectRef.current.state.focusedOption));
                    setDirectionKey(event.key);
                    selectRef.current.onMenuClose();
                }
                break;
            case 'Tab':
                if (value?.id === selectRef.current.state.focusedOption.value) {
                    event.preventDefault();
                    api.tabToNextCell();
                }
                break;
        }
    };

    const handleOptionChange = (newValue: CustomOptionType | null) => {
        onValueChange(newValue ? customOptionTypeToFieldOption(newValue) : undefined);
        api.tabToNextCell();
    };

    useEffect(() => {
        // focus on the input
        setTimeout(() => {
            if (selectRef.current !== null) {
                selectRef.current.focus();
            }
        });
    }, []);

    useEffect(() => {
        if (!selectRef.current?.state.selectValue.length || selectRef.current.state.selectValue[0].value === value?.id)
            return;
        if (directionKey === 'ArrowLeft') api.tabToPreviousCell();
        else api.tabToNextCell();
    }, [selectRef.current?.state.selectValue]);

    const customFilterOption = ({ label }: FilterOptionOption<CustomOptionType>, inputValue: string) =>
        label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;

    const customSelectStyles = createCustomSelectStyles(column.getActualWidth());

    return (
        <Select
            ref={selectRef}
            className='order__select'
            styles={customSelectStyles}
            menuPlacement='bottom'
            components={{ Option: CustomOption }}
            openMenuOnFocus
            placeholder={false}
            filterOption={customFilterOption}
            options={[
                ...(value ? [fieldOptionToCustomOptionType(value)] : []),
                ...values
                    .filter((value1: FieldOption) => value1.id !== value?.id)
                    .map((val: FieldOption) => fieldOptionToCustomOptionType(val)),
            ]}
            value={value ? fieldOptionToCustomOptionType(value) : value}
            onChange={handleOptionChange}
            onKeyDown={handleKeyDown}
        />
    );
};

export default ISelectEditor;
