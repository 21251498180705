import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Box, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';

import SettingsIcon from '../../assets/images/icons/settings.svg';
import Order from '../../components/Order';
import ErrorFlash from '../../components/Order/flash/ErrorFlash';
import LoadingIcon from '../../components/loading-icon';
import { getCustomers } from '../../lib/customer';
import { createOrder } from '../../lib/order';
import { Order as OrderType } from '../../lib/order/types';
import { RootState } from '../../redux';
import { getCategory } from '../../redux/categories/actions';
import { Customer } from '../../redux/customer/types';
import { endFixOrder } from '../../redux/fix-order/actions';
import { clearOrder } from '../../redux/orders/actions';
import { api } from '../../utils/api';

const Category = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const category = useSelector((state: RootState) => state.categories.categories[parseInt(id as string, 10)]);
    const oidcUser = useSelector((state: RootState) => state.oidc.user);
    const loadedOrder = useSelector((state: RootState) => state.orders.order);
    const { id: fixingOrderId, info: fixingOrderInfo } = useSelector((state: RootState) => state.fixOrder);

    const [customers, setCustomers] = useState<Customer[] | null>();
    const [order, setOrder] = useState<OrderType | undefined>(loadedOrder);
    const [error, setError] = useState({ error: '', show: false });

    const [hidePrice, setHidePrice] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getCategory(parseInt(id as string, 10)));

        return () => {
            dispatch(clearOrder());
            dispatch(endFixOrder());
        };
    }, [dispatch, id]);

    useEffect(() => {
        if (!oidcUser?.access_token || !category?.id) return;
        getCustomers(oidcUser.access_token, category.id, undefined, 1).then((customers) => setCustomers(customers));
    }, [oidcUser?.access_token, category?.id]);

    useEffect(() => {
        if (!category?.id || !customers || customers.length === 0) return;
        if (order?.id) {
            document.title = `Tilausportaali - Kategoria: ${category.name} - Tilaus: ${order.id}`;
            return;
        }

        document.title = `Tilausportaali - Kategoria: ${category.name}`;

        createOrder(oidcUser?.access_token ?? '', { category: category.id, customer: customers[0].id, reference: '' })
            .then((order) => setOrder(order))
            .catch((err) => {
                console.error(err);
                showError(
                    'Tilauksen alustaminen epäonnistui. Yritä ladata sivu uudelleen. Mikäli ongelma jatkuu, ' +
                        'olethan yhteydessä ylläpitoon.',
                );
            });
    }, [category?.id, category?.name, order?.id, oidcUser?.access_token, customers]);

    useEffect(() => {
        const deleteEmptyOrder = () => order?.id && api.keepAlive('DELETE', `/order/${order.id}/empty`, {});

        window.addEventListener('beforeunload', deleteEmptyOrder);
        return () => {
            deleteEmptyOrder();
            window.removeEventListener('beforeunload', deleteEmptyOrder);
        };
    }, [order?.id]);

    const showError = (error: string) => setError({ error, show: true });
    const hideError = () => setError(({ error }) => ({ error, show: false }));

    const setPriceHidden = () => setHidePrice(!hidePrice);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className='main-wrapper'>
            {category ? (
                <>
                    <div className='category-title-row'>
                        <h1>{category.name}</h1>
                    </div>

                    <div className='category-settings'>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <Tooltip title='Asetukset'>
                                <IconButton
                                    onClick={handleClick}
                                    size='small'
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <img className='settings-icon' src={SettingsIcon} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id='account-menu'
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            style={{
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            }}
                            PaperProps={{
                                elevation: 0,
                            }}
                            transformOrigin={{
                                vertical: -45,
                                horizontal: 120,
                            }}
                        >
                            <MenuItem onClick={setPriceHidden}>
                                {!hidePrice ? 'Piilota nettohinta' : 'Näytä nettohinta'}
                            </MenuItem>
                        </Menu>
                    </div>

                    {fixingOrderId && (
                        <div className='order__fix-order-info'>
                            <div className='order__fix-order-info-title'>Korjattavan tilauksen tiedot:</div>
                            <div>
                                <span className='order__fix-order-info-label'>Aika</span>
                                {new Date(fixingOrderInfo?.time ?? 0).toLocaleString()}
                            </div>
                            <div>
                                <span className='order__fix-order-info-label'>Tila</span>
                                {fixingOrderInfo?.status ?? ''}
                            </div>
                        </div>
                    )}

                    {customers && customers.length === 0 ? (
                        <span>
                            Sinulle ei ole kytketty asiakkuutta, joka tarvitaan tilauksen tekemiseen. Olet hyvä ja ota
                            yhteyttä ylläpitoon.
                        </span>
                    ) : order ? (
                        <Order
                            category={category}
                            disableExcel={fixingOrderId !== undefined}
                            enablePriceEdit={fixingOrderInfo !== undefined}
                            errorVisible={error.show}
                            hideError={hideError}
                            order={order}
                            showError={showError}
                            hidePrice={hidePrice}
                        />
                    ) : (
                        <LoadingIcon />
                    )}
                </>
            ) : (
                <LoadingIcon />
            )}

            <ErrorFlash {...error} />
        </div>
    );
};

export default Category;
