import CloseIcon from '../../../assets/images/icons/close.svg';
import UndoIcon from '../../../assets/images/icons/undo.svg';

interface Props {
    handleClose: () => void;
    handleUndo: () => void;
    show: boolean;
    showHigher: boolean;
}

const UndoFlash = ({ handleClose, handleUndo, show, showHigher }: Props) => (
    <div className={`flash flash-primary ${show && (showHigher ? 'show-higher' : 'show')}`}>
        <img className='flash-icon' src={UndoIcon} alt='Kumoa' />
        <a onClick={handleUndo}>Kumoa rivin poisto</a>
        <button className='flash-clear btn btn-link btn-w-icon' onClick={handleClose}>
            <img src={CloseIcon} alt='Sulje' />
        </button>
    </div>
);

export default UndoFlash;
