import { ReactNode, useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';

import { BrowserHistory } from 'history';

interface Props {
    children: ReactNode;
    history: BrowserHistory;
}

const CustomRouter = ({ children, history }: Props) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router location={state.location} navigator={history} navigationType={state.action}>
            {children}
        </Router>
    );
};

export default CustomRouter;
