import Modal from 'react-modal';

import CloseIcon from '../../../assets/images/icons/close.svg';

interface Props {
    handleClose: () => void;
    open: boolean;
}

const ExcelFailModal = ({ handleClose, open }: Props) => (
    <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={{
            content: {
                paddingTop: '50px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'column',
            },
        }}
        ariaHideApp={false}
    >
        <button className='modal__close btn btn-icon' onClick={handleClose}>
            <img src={CloseIcon} alt='sulje' />
        </button>
        <div className='modal__title-wrap'>
            <div className='modal__title modal__title--center'>
                Excelin lataaminen epäonnistui. Tarkista excelin sisältö ja varmista, että käytät oikeaa kategoriaa.
            </div>
        </div>
        <div className='modal__actions btn-row stack-element'>
            <button className='btn btn-primary' onClick={handleClose}>
                Sulje
            </button>
        </div>
    </Modal>
);

export default ExcelFailModal;
