import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { sendOrder } from '../../../lib/order';
import { RootState } from '../../../redux';
import SubmitConfirmModal from '../modal/SubmitConfirmModal';
import SubmitFailModal from '../modal/SubmitFailModal';
import SubmitSuccessModal from '../modal/SubmitSuccessModal';
import { Status } from '../types';

interface Props {
    enableStrictMode: () => void;
    orderId: number;
    salesConditionAccepted: boolean;
    status: Status;
}

const OrderSubmit = ({ enableStrictMode, orderId, salesConditionAccepted, status }: Props) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [submitStatus, setSubmitStatus] = useState<'CONFIRM' | 'SUCCESS' | 'FAIL' | null>(null);

    const oidcUser = useSelector((state: RootState) => state.oidc.user);

    const handleOnClick = () => {
        enableStrictMode();
        setSubmitStatus('CONFIRM');
    };

    const handleSubmit = () => {
        setLoading(true);
        sendOrder(oidcUser?.access_token ?? '', orderId)
            .then(() => setSubmitStatus('SUCCESS'))
            .catch((err) => {
                console.error(err);
                setSubmitStatus('FAIL');
            })
            .finally(() => setLoading(true));
    };

    return (
        <>
            <button className='btn btn-primary' onClick={handleOnClick}>
                Lähetä tilaus
            </button>

            <SubmitConfirmModal
                error={status === 'ERROR'}
                handleClose={() => setSubmitStatus(null)}
                handleSubmit={handleSubmit}
                loading={loading || status === 'LOADING'}
                open={submitStatus === 'CONFIRM'}
                orderIsEmpty={status === 'EMPTY'}
                orderIsInvalid={status === 'INVALID_CELL'}
                orderPricesOrProductCodesAreInvalid={status === 'INVALID_PRODUCT_CODE' || status === 'INVALID_PRICE'}
                salesConditionAccepted={salesConditionAccepted}
            />
            <SubmitSuccessModal handleClose={() => navigate('/')} open={submitStatus === 'SUCCESS'} />
            <SubmitFailModal handleClose={() => setSubmitStatus(null)} open={submitStatus === 'FAIL'} />
        </>
    );
};

export default OrderSubmit;
