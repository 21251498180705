import {
    CellClassParams,
    ColDef,
    ICellRendererParams,
    ValueFormatterParams,
    ValueGetterParams,
} from 'ag-grid-community';
import { SuppressKeyboardEventParams, ValueParserParams } from 'ag-grid-community/dist/types/core/entities/colDef';

import { OrderField, OrderRow, PriceWrapper, ProductCodeWrapper } from '../../lib/order/types';
import { Field } from '../../redux/categories/types';
import { validateCell, validateOrderFields } from '../../utils/order';
import INumberEditor from './editors/INumberEditor';
import ISelectEditor from './editors/ISelectEditor';
import ITextEditor from './editors/ITextEditor';
import { GridRow, PriceTotals, Status } from './types';

export const columnSizeMapping = {
    SMALL: 1,
    MEDIUM: 2,
    LARGE: 3,
};

export const defaultColDef = {
    editable: true,
    resizable: true,
    sortable: false,
};

// FUNCTIONS

const getProductCodeColumnDef = (): ColDef => ({
    field: 'productCode',
    headerName: 'Tuotekoodi',
    editable: false,
    resizable: false,
    width: 75,
    cellStyle: ({ value }: CellClassParams<unknown, ProductCodeWrapper>) => ({
        backgroundColor:
            value?.status === 'LEMONSOFT_ERROR' || value?.status === 'ORDER_PORTAL_ERROR' ? '#f44336' : 'initial',
    }),
    lockPosition: 'left',
    pinned: 'left',
    suppressNavigable: true,
    valueFormatter: ({ value }: ValueFormatterParams<unknown, ProductCodeWrapper>) => value?.productCode || '-',
});

const getSquaresColumnDef = (): ColDef => ({
    field: 'squares',
    headerName: 'm2',
    editable: false,
    resizable: false,
    width: 74,
    cellStyle: { textAlign: 'center' },
    lockPosition: 'right',
    pinned: 'right',
    suppressNavigable: true,
    valueFormatter: (params: ValueFormatterParams<unknown, PriceWrapper>) =>
        formatSquares(params.value?.status === 'OK' ? params.value.totalPriceSquares : undefined),
});

const getPriceColumnDefs = (enablePriceEdit: boolean, hideNetPrice: boolean): ColDef[] => [
    {
        field: 'unitPrice',
        headerName: 'à/€',
        editable: enablePriceEdit,
        resizable: false,
        width: 74,
        cellStyle: ({ data }: CellClassParams<{ unitPrice: PriceWrapper }>) => ({
            backgroundColor:
                data?.unitPrice.status === 'LEMONSOFT_ERROR' || data?.unitPrice.status === 'ORDER_PORTAL_ERROR'
                    ? '#f44336'
                    : 'initial',
            textAlign: 'center',
        }),
        lockPosition: 'right',
        pinned: 'right',
        suppressNavigable: true,
        valueFormatter: (params: ValueFormatterParams<unknown, string>) =>
            formatPrice(params.value ? parseFloat(params.value) * 100 : undefined),
        valueGetter: (params: ValueGetterParams<{ unitPrice: PriceWrapper }, PriceWrapper>) =>
            params.data?.unitPrice.status === 'OK' ? (params.data.unitPrice.unitPrice / 100).toFixed(2) : '',
        valueParser: (params) => {
            const parsedValue = parseFloat(params.newValue.replace(',', '.'));
            return !isNaN(parsedValue) && isFinite(parsedValue)
                ? { status: 'OK', unitPrice: parsedValue * 100 }
                : { status: 'NOT_INITIALIZED' };
        },
    },
    {
        field: 'totalPrice',
        headerName: 'OVH',
        editable: false,
        resizable: false,
        width: 74,
        cellStyle: { textAlign: 'center' },
        lockPosition: 'right',
        pinned: 'right',
        suppressNavigable: true,
        valueFormatter: (params: ValueFormatterParams<unknown, PriceWrapper>) =>
            formatPrice(params.value?.status === 'OK' ? params.value.totalPrice : undefined),
    },
    {
        field: 'totalFinalPrice',
        headerName: 'Netto',
        editable: false,
        resizable: false,
        width: 74,
        cellStyle: { textAlign: 'center' },
        lockPosition: 'right',
        pinned: 'right',
        hide: hideNetPrice ? true : false,
        suppressNavigable: true,
        valueFormatter: (params: ValueFormatterParams<unknown, PriceWrapper>) =>
            formatPrice(params.value?.status === 'OK' ? params.value.totalFinalPrice : undefined),
    },
];

export const generateColDefs = (
    fields: Field[],
    calculatePrice: boolean,
    enablePriceEdit: boolean,
    showSquares: boolean,
    hideNetPrice: boolean,
    strictMode: boolean,
): ColDef[] => [
    ...(calculatePrice ? [getProductCodeColumnDef()] : []),
    ...fields
        .filter(({ hidden }) => !hidden)
        .map(({ field }) => ({
            field: `field-${field.id}`,
            headerName: field.name,
            headerTooltip: field.help,
            minWidth: 40,
            flex: columnSizeMapping[field.width],
            cellEditor: getCellEditor(field),
            cellEditorParams: getCellEditorParams(field),
            cellEditorPopup: getCellEditorPopup(field),
            valueFormatter: (params: ValueFormatterParams<unknown, string>) => (params?.value ? params.value : ''),
            valueParser: (params: ValueParserParams<unknown, string>) => (params?.newValue ? params.newValue : ''),
            cellRenderer: (params: ICellRendererParams) => cellRendererSelector(params, field),
            cellStyle: (params: CellClassParams) => {
                const field = fields.find((field) => `field-${field.field.id}` === params.colDef.field);
                return {
                    backgroundColor:
                        strictMode &&
                        (!calculatePrice ||
                            ['EMPTY', 'NOT_INITIALIZED'].indexOf(params.data?.unitPrice.status) === -1) &&
                        field &&
                        !validateCell(params.value, field, strictMode)
                            ? '#f44336'
                            : 'initial',
                };
            },
            ...(field['@type'] === 'Select'
                ? {
                      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) =>
                          (params.event.key === 'Enter' || params.event.key === 'Tab') && !params.event.shiftKey,
                  }
                : {}),
        })),
    ...(calculatePrice && showSquares ? [getSquaresColumnDef()] : []),
    ...(calculatePrice ? getPriceColumnDefs(enablePriceEdit, hideNetPrice) : []),
];

export const orderRowToGridRow = (row: OrderRow, includeProductCode: boolean, includePrices: boolean): GridRow => ({
    id: row.id,
    ...(includeProductCode && { productCode: row.productCode }),
    ...row.fields.reduce((obj, { fieldId, value }) => ({ ...obj, [`field-${fieldId}`]: value }), {}),
    ...(includePrices && {
        squares: row.price,
        unitPrice: row.price,
        totalPrice: row.price,
        totalFinalPrice: row.price,
    }),
});

const findTypeByFieldId = (fields: Field[], id: number) =>
    fields.find(({ field }) => field.id === id)?.field['@type'] ?? 'Text';

export const gridRowToOrderRow = (row: GridRow, fields: Field[]): OrderRow =>
    ({
        id: row.id,
        fields: Object.entries(row)
            .filter(([key]) => key.startsWith('field-'))
            .map(([key, value]) => ({ fieldId: parseInt(key.replace('field-', '')), value }))
            .map(
                ({ fieldId, value }) =>
                    ({ '@type': findTypeByFieldId(fields, fieldId), fieldId, value }) as unknown as OrderField,
            ),
    }) as OrderRow;

export const getCellEditor = (field: Field['field']) => {
    // Special case
    if (field.name === 'Lisätiedot') return 'agLargeTextCellEditor';
    if (field['@type'] === 'Select') return ISelectEditor;
    if (field['@type'] === 'Number') return INumberEditor;
    return ITextEditor;
};

export const getCellEditorParams = (field: Field['field']) => {
    if (field['@type'] === 'Select')
        return {
            values: field.options ? field.options.map((x) => x) : [],
        };
    if (field['@type'] === 'Number') return undefined;
    return { maxLength: field.maxLength };
};

export const getCellEditorPopup = (field: Field['field']): boolean =>
    field['@type'] === 'Select' || field.name === 'Lisätiedot';

export const cellRendererSelector = (params: ICellRendererParams, field: Field['field']): string => {
    if (field['@type'] === 'Select') return params.value ? (params.value.name ?? '') : '';
    return params.value ?? '';
};

export const formatPrice = (x: number | undefined): string =>
    x !== undefined ? `${(x / 100).toFixed(2).toString().replace('.', ',')} €` : '-';

export const formatSquares = (x: number | undefined): string =>
    x !== undefined ? `${(x / 1000000).toFixed(2).toString().replace('.', ',')} m2` : '-';

export const getEmptyPriceTotals = (): PriceTotals => ({
    count: 0,
    area: 0,
    price: 0,
    finalPrice: 0,
});

export const getOrderRowStatus = (orderRow: OrderRow, fields: Field[]): Status =>
    orderRow.price.status === 'EMPTY' || orderRow.price.status === 'NOT_INITIALIZED'
        ? 'EMPTY'
        : !validateOrderFields(orderRow, fields)
          ? 'INVALID_CELL'
          : orderRow.productCode.status !== 'OK'
            ? 'INVALID_PRODUCT_CODE'
            : orderRow.price.status !== 'OK'
              ? 'INVALID_PRICE'
              : 'OK';

export const getOrderRowStatusByPrice = (price: PriceWrapper, oldRowStatus: Status): Status =>
    price.status === 'EMPTY'
        ? 'EMPTY'
        : oldRowStatus === 'INVALID_CELL' || oldRowStatus === 'INVALID_PRODUCT_CODE'
          ? oldRowStatus
          : price.status !== 'OK'
            ? 'INVALID_PRICE'
            : 'OK';

export const combineStatuses = (statuses: Set<Status>): Status =>
    statuses.has('ERROR')
        ? 'ERROR'
        : statuses.has('INVALID_CELL')
          ? 'INVALID_CELL'
          : statuses.has('INVALID_PRODUCT_CODE')
            ? 'INVALID_PRODUCT_CODE'
            : statuses.has('INVALID_PRICE')
              ? 'INVALID_PRICE'
              : statuses.has('LOADING')
                ? 'LOADING'
                : statuses.has('OK')
                  ? 'OK'
                  : 'EMPTY';
