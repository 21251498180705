import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select, { StylesConfig } from 'react-select';

import SpreadsheetIcon from '../../assets/images/icons/spreadsheet.svg';
import { getCustomers } from '../../lib/customer';
import { RootState } from '../../redux';
import { Customer } from '../../redux/customer/types';

interface Props {
    categoryId?: number;
    selectedCustomer?: Customer;
    setSelectedCustomer: (_?: Customer) => void;
    type?: 'simple' | 'full';
    selectStyles?: StylesConfig<CustomOptionType, false>;
}

const CustomerPicker = ({ categoryId, selectStyles, selectedCustomer, setSelectedCustomer, type = 'full' }: Props) => {
    const oidcUser = useSelector((state: RootState) => state.oidc.user);

    const [customers, setCustomers] = useState<Customer[] | null>(null);
    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        if (!oidcUser?.access_token) return;
        getCustomers(oidcUser.access_token, categoryId, search).then((customers) => setCustomers(customers));
    }, [oidcUser?.access_token, categoryId, search]);

    useEffect(() => {
        if (customers?.length !== 1 || selectedCustomer?.id === customers[0].id) return;
        setSelectedCustomer(customers[0]);
    }, [customers, selectedCustomer?.id, setSelectedCustomer]);

    const handleChange = (e: CustomOptionType | null, { action }: { action: string }) => {
        if (action === 'clear') setSearch('');
        setSelectedCustomer(e?.value);
    };

    const handleInputChange = (input: string, { action }: { action: string }) => {
        if (action === 'input-change') setSearch(input);
    };

    if (customers === null || customers.length <= 1) return null;

    return type === 'full' ? (
        <>
            <h2>Asiakas</h2>
            <div className='row-wrapper customer-picker'>
                <Select<CustomOptionType>
                    className='input__quarter'
                    options={customers.map((customer) => ({
                        label: (
                            <>
                                {customer.excelAllowed && <img className='spreadsheet-icon' src={SpreadsheetIcon} />}
                                <span className='label'>
                                    {customer.company} - {customer.name}
                                </span>
                            </>
                        ),
                        value: customer,
                    }))}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    placeholder={selectedCustomer ? `${selectedCustomer.company} - ${selectedCustomer.name}` : ''}
                    isClearable={true}
                    isOptionDisabled={(option) => !option?.value.categoryAllowed}
                    filterOption={() => true}
                    styles={{ menu: (menuStyles) => ({ ...menuStyles, zIndex: 9999 }) }}
                />
            </div>
        </>
    ) : (
        <div className='order-info__search-input-wrapper customer-picker'>
            <label htmlFor='old-orders-select-customer'>Asiakas</label>
            <Select<CustomOptionType>
                id='old-orders-select-customer'
                className='order-info__search-input order-info__search-category'
                classNamePrefix='react-select'
                styles={selectStyles}
                options={customers.map((customer) => ({
                    label: (
                        <>
                            {customer.company} - {customer.name}
                        </>
                    ),
                    value: customer,
                }))}
                onChange={handleChange}
                onInputChange={handleInputChange}
                placeholder={selectedCustomer ? `${selectedCustomer.company} - ${selectedCustomer.name}` : ''}
                isClearable={true}
                filterOption={() => true}
            />
        </div>
    );
};

export type CustomOptionType = {
    label: JSX.Element;
    value: Customer;
};

export default CustomerPicker;
